<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
          <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Statistik
            <small
              >Hasil Verifikasi Penilaian Mandiri PTSP Pemda, PPB Pemda, dan PPB
              K/L</small
            >
          </h3>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-5">
        <!--begin::Item-->
        <div class="mb-12">
          <!--begin::Content-->
          <div>
            <div>
              <b-tabs content-class="mt-3">
                <b-tab title="PTSP" active>
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="input-with-list"
                            >Pilih jenis pemerintah</label
                          >
                          <b-form-select
                            @change="loaddataPTSP()"
                            class="form-control form-select"
                            style="float: right"
                            v-model="selectedDaerah"
                            :options="optionsDaerah"
                          ></b-form-select>
                        </div>
                        <div class="col-md-6">
                          <label for="input-with-list">Pilih indikator</label>
                          <s-select
                            @input="loaddataPTSP()"
                            :options="optionsApiPTSP"
                            v-model="selectedApiPTSP"
                            :reduce="(optionsApiPTSP) => optionsApiPTSP.value"
                            label="text"
                          >
                            <template v-slot:option="option">
                              <span v-if="option.tipe === 'kriteria'">
                                <b>{{ option.text }}</b></span
                              >
                            </template>
                          </s-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div style="text-align: center">
                          <h3>{{ titlePTSP }}</h3>
                        </div>
                        <div class="row">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div id="chart">
                              <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                              <apexchart
                                type="pie"
                                height="300px"
                                :options="chartOptionsPiePTSP"
                                :series="seriesPiePTSP"
                              ></apexchart>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title="PPB">
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="input-with-list"
                            >Pilih jenis pemerintah</label
                          >
                          <b-form-select
                            @change="loaddataPPB()"
                            class="form-control form-select"
                            style="float: right"
                            v-model="selectedDaerah"
                            :options="optionsDaerah"
                          ></b-form-select>
                        </div>
                        <div class="col-md-6">
                          <label for="input-with-list">Pilih indikator</label>
                          <s-select
                            @input="loaddataPPB()"
                            :options="optionsApiPPB"
                            v-model="selectedApiPPB"
                            :reduce="(optionsApiPPB) => optionsApiPPB.value"
                            label="text"
                          >
                            <template v-slot:option="option">
                              <span v-if="option.tipe === 'kriteria'">
                                <b>{{ option.text }}</b></span
                              >
                            </template>
                          </s-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div style="text-align: center">
                          <h3>{{ titlePPB }}</h3>
                        </div>
                        <div class="row">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div id="chart">
                              <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                              <apexchart
                                type="pie"
                                height="300px"
                                :options="chartOptionsPiePPB"
                                :series="seriesPiePPB"
                              ></apexchart>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title="PPB KL">
                  <div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="input-with-list">Pilih indikator</label>
                          <s-select
                            @input="loaddataPPBKL()"
                            :options="optionsApiPPBKL"
                            v-model="selectedApiPPBKL"
                            :reduce="(optionsApiPPBKL) => optionsApiPPBKL.value"
                            label="text"
                          >
                            <template v-slot:option="option">
                              <span v-if="option.tipe === 'kriteria'">
                                <b>{{ option.text }}</b></span
                              >
                            </template>
                          </s-select>
                        </div>
                        <div class="col-md-6"></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div style="text-align: center">
                          <h3>{{ titlePPBKL }}</h3>
                        </div>
                        <div class="row">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div id="chart">
                              <apexchart
                                type="pie"
                                height="300px"
                                :options="chartOptionsPiePPBKL"
                                :series="seriesPiePPBKL"
                              ></apexchart>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>

    <!-- Modal lihat -->
    <b-modal id="modal-detail" size="xl" hide-backdrop content-class="shadow">
      <template #modal-title>
        <span>{{ detailJudul }}</span>
        <!-- <span class="badge" :style="'background-color:'+warna+';color:white'">{{jawaban}}</span> -->
      </template>
      <div>
        <div style="overflow-x: auto">
          <table
            id="example"
            class="table table-striped table-bordered"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Daerah</th>
                <th>Total Penilaian Mandiri</th>
                <th>Total Asosiasi</th>
                <th>Total PPB</th>
                <th>Total Akhir</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in detailStatistik" :key="row.id">
                <td></td>
                <td>{{ row.daerah }}</td>
                <td>
                  {{ row.total_pm }}
                </td>
                <td>
                  {{ row.total_asosiasi }}
                </td>
                <td>
                  {{ row.total_ppb }}
                </td>
                <td>
                  {{ row.total_akhir }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="$bvModal.hide('modal-detail')"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-detailKL" size="xl" hide-backdrop content-class="shadow">
      <template #modal-title>
        <span>{{ detailJudul }}</span>
        <!-- <span class="badge" :style="'background-color:'+warna+';color:white'">{{jawaban}}</span> -->
      </template>
      <div>
        <div style="overflow-x: auto">
          <table
            id="example2"
            class="table table-striped table-bordered"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Kementerian/Lembaga</th>
                <th>Total PPB</th>
                <th>Total Akhir</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in detailStatistik" :key="row.id">
                <td></td>
                <td>{{ row.nama_kl }}</td>
                <td>
                  {{ row.total_ppb }}
                </td>
                <td>
                  {{ row.total_akhir }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="$bvModal.hide('modal-detailKL')"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
  <!-- </div>
    </div> -->
</template>
  <style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
  <style lang="">
</style>
  <script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";

Vue.component("s-select", vSelect);
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      test: "hai",
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      totskor: [],
      search: "",
      headers: [
        {
          text: "Nama",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Ket", value: "ket" },
        { text: "Icon", value: "icon" },
        { text: "Aksi", value: "" },
      ],
      optionsDaerah: [
        { value: "provinsi", text: "Provinsi" },
        { value: "kabupaten", text: "Kabupaten" },
        { value: "kota", text: "Kota" },
      ],
      selectedDaerah: "provinsi",
      optionsApiPPB: [],
      selectedApiPPB: "p1a1",

      optionsApiPTSP: [],
      selectedApiPTSP: "pb1a1",

      optionsApiPPBKL: [],
      selectedApiPPBKL: "p1a1",

      titlePPB: "",
      persentasePPB: [],
      seriesPiePPB: [],
      chartOptionsPiePPB: {},
      jawabanPPB: null,
      detailStatistik: [],
      //   chartOptionsPiePPB: {
      //     colors:[],
      //     chart: {
      //
      //       type: 'pie',
      //     //   events: {
      //     //     dataPointSelection: function(event, chartContext, config) {
      //     //         console.log(config)
      //     // // console.log(config.config.series[config.seriesIndex].name)
      //     // // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
      //     //     }
      //     // }
      //     },
      //     labels: [],
      //     legend: {
      //         position: 'right',
      //         offsetX: 200,
      //         // horizontalAlign: 'right',
      //     },
      //     responsive: [{
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200
      //         },
      //         legend: {
      //             position: 'bottom',
      //             offsetX: -10,
      //             offsetY: 0,
      //             horizontalAlign: 'left',
      //         }
      //       }
      //     }]
      //   },

      titlePTSP: "",
      persentasePTSP: [],
      seriesPiePTSP: [],
      jawabanPTSP: null,
      chartOptionsPiePTSP: {
        // colors:[],
        // chart: {
        //
        //   type: 'pie',
        // },
        // labels: [],
        // legend: {
        //     position: 'right',
        //     offsetX: 200,
        //     // horizontalAlign: 'right',
        // },
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       width: 200
        //     },
        //     legend: {
        //         position: 'bottom',
        //         offsetX: -10,
        //         offsetY: 0,
        //         horizontalAlign: 'left',
        //     }
        //   }
        // }]
      },
      detailJudul: null,

      titlePPBKL: "",
      persentasePPBKL: [],
      seriesPiePPBKL: [],
      chartOptionsPiePPBKL: {
        // colors:[],
        // chart: {
        //
        //   type: 'pie',
        // },
        // labels: [],
        // legend: {
        //     position: 'right',
        //     offsetX: 200,
        //     // horizontalAlign: 'right',
        // },
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       width: 200
        //     },
        //     legend: {
        //         position: 'bottom',
        //         offsetX: -10,
        //         offsetY: 0,
        //         horizontalAlign: 'left',
        //     }
        //   }
        // }]
      },
    };
  },
  components: {
    vSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Statistik" }]);
    this.getIndikatorPTSP();
    this.getIndikatorPPB();
    this.getIndikatorPPBKL();
    this.loadChartPTSP();
    this.loadChartPPB();
    this.loadChartPPBKL();
    this.loaddataPPB();
    this.loaddataPTSP();
    this.loaddataPPBKL();
  },
  // computed: {
  //     chartOptionsPiePPB: function () {
  //         return {
  //             colors:[],
  //             chart: {
  //
  //             type: 'pie',
  //               events: {
  //                 dataPointSelection: function(event, chartContext, config) {
  //                     console.log(config)
  //                     var indexLabel = config.dataPointIndex
  //                     console.log(this.chartOptionsPiePPB)
  //             // console.log(config.config.series[config.seriesIndex].name)
  //             // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
  //                 }
  //             }
  //             },
  //             labels: [],
  //             legend: {
  //                 position: 'right',
  //                 offsetX: 200,
  //                 // horizontalAlign: 'right',
  //             },
  //             responsive: [{
  //             breakpoint: 480,
  //             options: {
  //                 chart: {
  //                 width: 200
  //                 },
  //                 legend: {
  //                     position: 'bottom',
  //                     offsetX: -10,
  //                     offsetY: 0,
  //                     horizontalAlign: 'left',
  //                 }
  //             }
  //             }]
  //         }
  //     }
  // },
  methods: {
    getIndikatorPTSP() {
      axios
        .get(
          this.url + "/report/option?tipe=ptsp&daerah=" + this.selectedDaerah,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          var data = response.data.data;
          console.log("ini");
          console.log(data);
          for (let index = 0; index < data.length; index++) {
            this.optionsApiPTSP.push({
              text: data[index].text,
              value: data[index].variable,
              tipe: data[index].tipe,
            });
          }
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },
    getIndikatorPPB() {
      axios
        .get(
          this.url + "/report/option?tipe=ppb&daerah=" + this.selectedDaerah,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          var data = response.data.data;
          console.log("ini");
          console.log(data);
          for (let index = 0; index < data.length; index++) {
            this.optionsApiPPB.push({
              text: data[index].text,
              value: data[index].variable,
              tipe: data[index].tipe,
            });
          }
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },
    getIndikatorPPBKL() {
      axios
        .get(this.url + "/report/option?tipe=ppb&daerah=kl", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          var data = response.data.data;
          console.log("ini");
          console.log(data);
          for (let index = 0; index < data.length; index++) {
            this.optionsApiPPBKL.push({
              text: data[index].text,
              value: data[index].variable,
              tipe: data[index].tipe,
            });
          }
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },
    loadChartPTSP() {
      this.chartOptionsPiePTSP = {
        dataLabels: {
          enabled: true,
          // enabledOnSeries: undefined,
          formatter: (val, opts) => {
            console.log(this.persentasePTSP[opts.seriesIndex]);
            return (
              this.persentasePTSP[opts.seriesIndex] +
              "%" +
              " (" +
              opts.w.config.series[opts.seriesIndex] +
              ")"
            );
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
        },
        colors: [],
        chart: {
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.detailModalPTSP(config);
            },
          },
        },
        labels: [],
        legend: {
          position: "right",
          offsetX: 0,
          // horizontalAlign: 'right',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
                horizontalAlign: "left",
              },
            },
          },
        ],
      };
    },
    loadChartPPB() {
      this.chartOptionsPiePPB = {
        dataLabels: {
          enabled: true,
          // enabledOnSeries: undefined,
          formatter: (val, opts) => {
            console.log(this.persentasePPB[opts.seriesIndex]);
            return (
              this.persentasePPB[opts.seriesIndex] +
              "%" +
              " (" +
              opts.w.config.series[opts.seriesIndex] +
              ")"
            );
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
        },
        colors: [],
        chart: {
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.detailModalPPB(config);
              // console.log(config.config.series[config.seriesIndex].name)
              // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
            },
          },
        },
        labels: [],
        legend: {
          position: "right",
          offsetX: 0,
          // horizontalAlign: 'right',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
                horizontalAlign: "left",
              },
            },
          },
        ],
      };
    },
    loadChartPPBKL() {
      // if (this.selectedApiPPBKL === '&variable=p1b1') {
      this.chartOptionsPiePPBKL = {
        dataLabels: {
          enabled: true,
          // enabledOnSeries: undefined,
          formatter: (val, opts) => {
            console.log(this.persentasePPBKL);
            return (
              this.persentasePPBKL[opts.seriesIndex] +
              "%" +
              " (" +
              opts.w.config.series[opts.seriesIndex] +
              ")"
            );
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
        },
        colors: [],
        chart: {
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.detailModalPPBKL(config);
              // console.log(config.config.series[config.seriesIndex].name)
              // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
            },
          },
        },
        labels: [],
        legend: {
          position: "bottom",
          offsetX: 0,
          offsetY: 0,
          // horizontalAlign: 'left',
          // horizontalAlign: 'right',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
                horizontalAlign: "left",
              },
            },
          },
        ],
      };
      // } else {
      //     this.chartOptionsPiePPBKL={
      //         dataLabels: {
      //             enabled: true,
      //             // enabledOnSeries: undefined,
      //             formatter: (val, opts) =>{
      //                 return this.persentasePPBKL[opts.seriesIndex]+'%'+" ("+ opts.w.config.series[opts.seriesIndex]+")"
      //             },
      //             textAnchor: 'middle',
      //             distributed: false,
      //             offsetX: 0,
      //             offsetY: 0,
      //             style: {
      //                 fontSize: '14px',
      //                 fontFamily: 'Helvetica, Arial, sans-serif',
      //                 fontWeight: 'bold',
      //                 colors: undefined
      //             },
      //             background: {
      //                 enabled: true,
      //                 foreColor: '#fff',
      //                 padding: 4,
      //                 borderRadius: 2,
      //                 borderWidth: 1,
      //                 borderColor: '#fff',
      //                 opacity: 0.9,
      //                 dropShadow: {
      //                 enabled: false,
      //                 top: 1,
      //                 left: 1,
      //                 blur: 1,
      //                 color: '#000',
      //                 opacity: 0.45
      //                 }
      //             },
      //         },
      //         colors:[],
      //         chart: {

      //         type: 'pie',
      //         events: {
      //             dataPointSelection: (event, chartContext, config) => {
      //                 this.detailModalPPBKL(config)
      //         // console.log(config.config.series[config.seriesIndex].name)
      //         // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
      //             }
      //         }
      //         },
      //         labels: [],
      //         legend: {
      //             position: 'right',
      //             offsetX: 0,
      //             // horizontalAlign: 'right',
      //         },
      //         responsive: [{
      //         breakpoint: 480,
      //         options: {
      //             chart: {
      //             width: 200
      //             },
      //             legend: {
      //                 position: 'bottom',
      //                 offsetX: -10,
      //                 offsetY: 0,
      //                 horizontalAlign: 'left',
      //             }
      //         }
      //         }]
      //     }
      // }
    },
    detailModalPPB(data) {
      var arrayLabel = [];
      arrayLabel = this.chartOptionsPiePPB.labels;
      arrayLabel[data.dataPointIndex];
      this.jawabanPPB = arrayLabel[data.dataPointIndex];
      $("#example").DataTable().destroy();

      axios
        .get(
          this.url +
            "/report/statistik_detail?tahun=2024&tipe=ppb&daerah=" +
            this.selectedDaerah +
            "&variable=" +
            this.selectedApiPPB +
            "&jawaban=" +
            arrayLabel[data.dataPointIndex],
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.detailStatistik = response.data.data;
          this.detailJudul = response.data.judul;
          this.$bvModal.show("modal-detail");
          this.initDatatable();
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },
    detailModalPTSP(data) {
      var arrayLabel = [];
      arrayLabel = this.chartOptionsPiePTSP.labels;
      arrayLabel[data.dataPointIndex];
      this.jawabanPPB = arrayLabel[data.dataPointIndex];
      $("#example").DataTable().destroy();

      axios
        .get(
          this.url +
            "/report/statistik_detail?tahun=2024&tipe=ptsp&daerah=" +
            this.selectedDaerah +
            "&variable=" +
            this.selectedApiPTSP +
            "&jawaban=" +
            arrayLabel[data.dataPointIndex],
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.detailStatistik = response.data.data;
          this.detailJudul = response.data.judul;
          this.$bvModal.show("modal-detail");
          this.initDatatable();
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },
    detailModalPPBKL(data) {
      var arrayLabel = [];
      arrayLabel = this.chartOptionsPiePPBKL.labels;
      arrayLabel[data.dataPointIndex];
      this.jawabanPPB = arrayLabel[data.dataPointIndex];
      $("#example2").DataTable().destroy();

      axios
        .get(
          this.url +
            "/report/statistik_detail?tahun=2024&tipe=ppb&daerah=kl" +
            "&variable=" +
            this.selectedApiPPBKL +
            "&jawaban=" +
            arrayLabel[data.dataPointIndex],
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.detailStatistik = response.data.data;
          this.detailJudul = response.data.judul;
          this.$bvModal.show("modal-detailKL");
          this.initDatatable2();
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
        });
    },
    loaddataPTSP() {
      var pieSeries = this.seriesPiePTSP;

      var pieWarna = this.chartOptionsPiePTSP.colors;
      while (pieWarna.length) {
        pieWarna.pop();
      }

      while (pieSeries.length) {
        pieSeries.pop();
      }

      var pieLabels = this.chartOptionsPiePTSP.labels;
      while (pieLabels.length) {
        pieLabels.pop();
      }
      axios
        .get(
          this.url +
            "/report/statistik?tahun=2024&tipe=ptsp&daerah=" +
            this.selectedDaerah +
            "&variable=" +
            this.selectedApiPTSP,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          var dataSeries = response.data.data.jumlah;
          var dataLabels = response.data.data.label;
          var dataWarna = response.data.data.warna;
          this.titlePTSP = response.data.judul;

          this.persentasePTSP = response.data.data.persentase;

          for (let index = 0; index < dataSeries.length; index++) {
            this.seriesPiePTSP.push(dataSeries[index]);
          }

          for (let index = 0; index < dataLabels.length; index++) {
            this.chartOptionsPiePTSP.labels.push(dataLabels[index]);
          }

          for (let index = 0; index < dataWarna.length; index++) {
            this.chartOptionsPiePTSP.colors.push(dataWarna[index]);
          }
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
          // this.loaddataPPB()
        });
    },
    loaddataPPB() {
      var pieSeries = this.seriesPiePPB;

      var pieWarna = this.chartOptionsPiePPB.colors;

      while (pieWarna.length) {
        pieWarna.pop();
      }

      while (pieSeries.length) {
        pieSeries.pop();
      }

      var pieLabels = this.chartOptionsPiePPB.labels;
      while (pieLabels.length) {
        pieLabels.pop();
      }
      axios
        .get(
          this.url +
            "/report/statistik?tahun=2024&tipe=ppb&daerah=" +
            this.selectedDaerah +
            "&variable=" +
            this.selectedApiPPB,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          var dataSeries = response.data.data.jumlah;
          var dataLabels = response.data.data.label;
          var dataWarna = response.data.data.warna;
          this.titlePPB = response.data.judul;

          this.persentasePPB = response.data.data.persentase;

          for (let index = 0; index < dataSeries.length; index++) {
            this.seriesPiePPB.push(dataSeries[index]);
          }

          for (let index = 0; index < dataLabels.length; index++) {
            this.chartOptionsPiePPB.labels.push(dataLabels[index]);
          }

          for (let index = 0; index < dataWarna.length; index++) {
            this.chartOptionsPiePPB.colors.push(dataWarna[index]);
          }
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
          // this.loaddataPPB()
        });
    },
    loaddataPPBKL() {
      // this.chartOptionsPiePPBKL={}
      // this.loadChartPPBKL()
      var pieSeries = this.seriesPiePPBKL;
      while (pieSeries.length) {
        pieSeries.pop();
      }

      var pieLabels = this.chartOptionsPiePPBKL.labels;
      while (pieLabels.length) {
        pieLabels.pop();
      }

      var pieWarna = this.chartOptionsPiePPBKL.colors;
      while (pieWarna.length) {
        pieWarna.pop();
      }
      axios
        .get(
          this.url +
            "/report/statistik?tahun=2024&tipe=ppb&daerah=kl&variable=" +
            this.selectedApiPPBKL,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          var dataSeries = response.data.data.jumlah;
          var dataLabels = response.data.data.label;
          var dataWarna = response.data.data.warna;
          this.titlePPBKL = response.data.judul;

          this.persentasePPBKL = response.data.data.persentase;

          for (let index = 0; index < dataSeries.length; index++) {
            this.seriesPiePPBKL.push(dataSeries[index]);
          }

          for (let index = 0; index < dataLabels.length; index++) {
            this.chartOptionsPiePPBKL.labels.push(dataLabels[index]);
          }

          for (let index = 0; index < dataWarna.length; index++) {
            this.chartOptionsPiePPBKL.colors.push(dataWarna[index]);
          }
        })
        .catch((error) => {
          this.$bvToast.toast("Error", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return error;
          // this.loaddataPPB()
        });
    },
    format_desimal(value) {
      if (value) {
        return value.replace(".", ",");
      }
    },
    initDatatable() {
      var dataJudul = this.detailJudul;
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          dom: "Bfrtip",
          fnRowCallback: function (
            nRow,
            aData,
            iDisplayIndex,
            iDisplayIndexFull
          ) {
            //debugger;
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).html(index);
            return nRow;
          },
          columnDefs: [
            {
              targets: 0,
              render: function (data, type, row, meta) {
                console.log(meta.row);
                console.log(type == "export" ? meta.row : data);
                return type == "export" ? meta.row + 1 : data;
              },
            },
          ],
          buttons: [
            // 'excel', 'pdf',
            {
              extend: "excelHtml5",
              title: dataJudul,
              text: "Excel",
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5],
                orthogonal: "export",
                format: {
                  header: function (data, columnIdx) {
                    // if(columnIdx==7 || columnIdx==8 || columnIdx==9){
                    // return 'Aktivitas ' + data;
                    // }
                    // else if(columnIdx==10 || columnIdx==11){
                    //   return 'Verifikasi Dokumentatif ' + data;
                    // }
                    // else if(columnIdx==12 || columnIdx==13){
                    //   return 'Verifikasi Lapangan ' + data;
                    // }
                    // else if(columnIdx==14 || columnIdx==15){
                    //   return 'File BA ' + data;
                    // }
                    // else if(columnIdx==16 || columnIdx==17 || columnIdx==18){
                    //   return 'Predikat ' + data;
                    // }
                    // else{
                    return data;
                    // }
                  },
                  body: function (data, row, column, node) {
                    data = $("<p>" + data + "</p>").text();
                    return data.replace(",", ".");
                  },
                },
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();

                return dataJudul + " " + date;
              },
            },
          ],
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"],
          ],
          // order: [[7, 'asc']],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
          // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
          //     cell.innerHTML = i+1;
          // } );
        }).draw();
      }, 300);
    },
    initDatatable2() {
      var dataJudul = this.detailJudul;
      setTimeout(() => {
        var t = $("#example2").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          dom: "Bfrtip",
          fnRowCallback: function (
            nRow,
            aData,
            iDisplayIndex,
            iDisplayIndexFull
          ) {
            //debugger;
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).html(index);
            return nRow;
          },
          columnDefs: [
            {
              targets: 0,
              render: function (data, type, row, meta) {
                console.log(meta.row);
                console.log(type == "export" ? meta.row : data);
                return type == "export" ? meta.row + 1 : data;
              },
            },
          ],
          buttons: [
            // 'excel', 'pdf',
            {
              extend: "excelHtml5",
              title: dataJudul,
              text: "Excel",
              exportOptions: {
                columns: [0, 1, 2, 3],
                orthogonal: "export",
                format: {
                  header: function (data, columnIdx) {
                    // if(columnIdx==7 || columnIdx==8 || columnIdx==9){
                    // return 'Aktivitas ' + data;
                    // }
                    // else if(columnIdx==10 || columnIdx==11){
                    //   return 'Verifikasi Dokumentatif ' + data;
                    // }
                    // else if(columnIdx==12 || columnIdx==13){
                    //   return 'Verifikasi Lapangan ' + data;
                    // }
                    // else if(columnIdx==14 || columnIdx==15){
                    //   return 'File BA ' + data;
                    // }
                    // else if(columnIdx==16 || columnIdx==17 || columnIdx==18){
                    //   return 'Predikat ' + data;
                    // }
                    // else{
                    return data;
                    // }
                  },
                  body: function (data, row, column, node) {
                    data = $("<p>" + data + "</p>").text();
                    return data.replace(",", ".");
                  },
                },
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();

                return dataJudul + " " + date;
              },
            },
          ],
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"],
          ],
          // order: [[7, 'asc']],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
          // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
          //     cell.innerHTML = i+1;
          // } );
        }).draw();
      }, 300);
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  created() {
    //   this.getuser(this.user)
    // this.getlocaldata()
  },
};
</script>
  